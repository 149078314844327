.circle-loader {
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-left-color: #9e9e9e;
  animation-name: loader-spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 7em;
  height: 7em;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: #5cb85c;
  transition: border 500ms ease-out;
}

.load-error {
  -webkit-animation: none;
  animation: none;
  border-color: red;
  transition: border 500ms ease-out;
}

.checkmark {
  display: none;
}

.checkmark.draw:after {
  opacity: 1;
  height: 3.5em;
  width: 1.75em;
  transform-origin: left top;
  border-right: 3px solid #5cb85c;
  border-top: 3px solid #5cb85c;
  content: "";
  left: 1.65em;
  top: 3.6em;
  position: absolute;
  animation-duration: 1.2s;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
}

.checkmark.error {
  position: absolute;
  left: 3.25em;
  top: 1.45em;
  width: 4em;
  height: 4em;
}

.checkmark.error:before, .checkmark.error:after {
  position: absolute;
  content: " ";
  height: 4em;
  width: 3px;
  background-color: #f00;
}

.checkmark.error:before {
  transform: rotate(45deg);
}

.checkmark.error:after {
  transform: rotate(-45deg);
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 1.75em;
    opacity: 1;
  }
  40% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1;
  }
  100% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1;
  }
}

