.content {
  padding-top: 1em !important;
  padding-bottom: 0em !important;
}

.timeline {
  color: #000;
  padding-top: 1em;
}

.timeline ul {
  list-style-type: none;
  border-left: 2px solid #026374;
  padding: 10px 5px;
}

.timeline ul li {
  background-color: #f3f3f3;
  padding: 20px 20px;
  position: relative;
  transition: .5s;
  border-radius: 10px;
}

.timeline ul li span {
  color: #fff;
  display: inline-block;
  background-color: #0090A9;
  border-radius: 25px;
  padding: 2px 5px;
  font-size: 15px;
  text-align: center;
  margin-left: -0.4%;
}

span::first-letter,
.date::first-letter {
  text-transform: capitalize;
}

.timeline ul li .content h3 {
  color: #0090A9;
  font-size: 17px;
  padding-top: 5px;
}

.timeline ul li .content p {
  font-size: 15px;
}

.timeline ul li:before {
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  background-color: #0090A9;
  border-radius: 50%;
  left: -11px;
  top: 28px;
  transition: .5s;
}

.timeline ul li:hover {
  background-color: #e8e8e8;
}

.timeline ul li:hover:before {
  background-color: #00b0d0;
  box-shadow: 0px 0px 10px 2px #00b0d0;
}

@media (max-width: 300px) {
  .timeline {
    width: 100%;
    padding: 30px 5px 30px 10px;
  }

  .timeline ul li .content h3 {
    color: #34ace0;
    font-size: 15px;
  }
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #969696 !important;
}

.valid {
  color: #0090A9 !important;
}

.invalid {
  color: #BD887D !important;
}