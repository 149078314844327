/*
__Seed builder__
  (Read_only) Builder helper
*/
#app {
  position: fixed;
  width: 100%;
  height: 100%;
}

