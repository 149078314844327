

.dahsboard table td.free:hover {
    background-color: #B22222 !important;
}

@media (max-width: 768px) {
    #pagination nav{
        float: none !important;
    }
}