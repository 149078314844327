.step-title {
    color: #05BCC8;
}

.form-group .control-label .form-control {
    color: #5D87A8 !important;
}

.form-group-default {
    background-color: #fff;
    position: relative;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;
    width: 100%;
    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease;
}

.form-group-default.focused {
    background-color: #f0f0f0;
}

.form-group-default.required:after {
    color: #f55753;
    content: "*";
    font-size: 20px;
    position: absolute;
    right: 12px;
    top: 6px;
}

.form-group-default .form-control {
    border: 0;
    padding: 0;
    margin-top: -4px;
    background: 0 0;
    border-radius: 0;
    resize: vertical;
}

.form-group-default .form-control:not(textarea) {
    height: 25px;
    min-height: 25px;
}

.form-group-default select.form-control {
    margin-left: -3px;
    height: inherit !important;
}

.form-group-default .form-control:focus {
    outline: 0 !important;
    box-shadow: none;
}

.form-group-default .control-label {
    margin: 0;
    display: block;
    line-height: 20px;
    margin-bottom: 2px;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.form-group-default .custom-select.is-invalid,
.form-group-default .form-control.is-invalid,
.was-validated .form-group-default .custom-select:invalid,
.was-validated .form-group-default .form-control:invalid {
    box-shadow: none;
    border-bottom: 1px solid #dc3545;
}

.form-group-default .custom-select.is-valid,
.form-group-default .form-control.is-valid,
.was-validated .form-group-default .custom-select:valid,
.was-validated .form-group-default .form-control:valid {
    box-shadow: none;
}

.chexbox-container {
    margin-top: -1.3%;
}

input[type=checkbox].form-control {
    width: 1.3rem !important;
    border-radius: 10px !important;
    accent-color: #0090A9 !important;
}