/* Scrollbar para navegadores basados en WebKit */
.nav::-webkit-scrollbar {
  width: 6px;
}

.nav::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 10px;
}

.nav::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

