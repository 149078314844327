.nav-no-css {
    border-radius: 0 !important;
    cursor: pointer;
}

.nav-no-css:hover {
    background-color: rgba(255, 255, 255, 0) !important;
}

.icon-no-css {
    color: #0090A9 !important;
}